.PasswordChange {

    h1 {
        color: #fff;
        margin-top: 0px;
    }
    label {
        color: #fff;

        margin-top: 20px;
        display: block;
    }
    input[type="password"] {
        caret-color: #c62828;
        width: calc(100% - 10px); 
        color: #fff;
        max-width: 400px;
        margin-top: 20px;
        display: block;
        height: 35px;
        border-radius: 5px;
        background-color: #101010;
        margin: 0px auto;
        padding-left: 5px; 
        padding-right: 5px;
    }
    
    


    button {
        background-color: #c62828;
        color: #fff;
        height: 35px;
        width: 100%;
        margin-top: 20px;
        max-width: 400px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        font-weight: bold;
    }

    button:disabled {
        height: 35px;
        margin-top: 20px;
        background-color: #252525;
        cursor: not-allowed;
        color: #171717; 
    }

    button:hover:not(:disabled) {
        background-color: #b71c1c;
    }
    ul {
        list-style-position: inside;
        padding-left: 0px;
    }

    p.error, p.warn, p.ok {
        &::before {
            vertical-align: middle;
            height: 1em;
            width: auto;
            margin-right: 0.5em;


        }
    }
    p.error::before {
        content: url("../../res/error.svg");
    }
    p.warn::before {
        content: url("../../res/warn.svg");
    }
    p.ok::before {
        content: url("../../res/ok.svg");
    }
}
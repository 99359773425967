body {
    background-color: #0a0a0a;

}
h1 {
    color: #fff;
}

#root {
    display: flex;
    flex-direction: row;

    .App {
        //width: 100%;
        max-width: 800px;
        margin: 10px auto 0px auto;
        padding: 90px;
        text-align: center;
        background-color: #0a0a0a;


    }
}